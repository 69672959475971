import 'jquery';
import 'popper.js';
import 'bootstrap';
import {WOW} from 'wowjs';
import 'owl.carousel';
import 'jquery.appear';
import 'jquery-countto';
import '@fancyapps/fancybox';



const Isotope = require('isotope-layout');

(function ($) {

    $.fn.doOnce = function (func) {
        this.length && func.apply(this);
        return this;
    };

    //tutaj pisac 100ms

    setTimeout(function(){
        jQuery('#start-click').click()
    },300);


    jQuery('.dropdown-menu .dropdown-toggle').on('click', function () {
        if (!jQuery(this).next().hasClass('show')) {
            jQuery(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }

        jQuery(this).next(".dropdown-menu").toggleClass('show');
        return false;
    });

    jQuery('.mega-menu-title').click(function () {
        if (!jQuery(this).next().hasClass('active')) {
            jQuery('.mega-dropdown-list').removeClass('active');
            jQuery(this).next().addClass('active');
        } else if (jQuery(this).next().hasClass('active')) {
            jQuery(this).next().removeClass('active');
        }
        return false;
    });

    jQuery(document).on('click', '[data-toggle-class]', function (e) {
        const $self = jQuery(this);
        const attr = $self.attr('data-toggle-class');
        const target = $self.attr('data-toggle-class-target') || $self.attr('data-target');
        const closest = $self.attr('data-target-closest');
        const classes = (attr && attr.split(',')) || '',
            targets = (target && target.split(',')) || Array($self);
        let key = 0;

        $.each(classes, function (index) {
            const target = closest ? $self.closest(targets[(targets.length === 1 ? 0 : key)]) : jQuery(targets[(targets.length === 1 ? 0 : key)]),
                current = target.attr('data-class'),
                _class = classes[index];
            (current !== _class) && target.removeClass(target.attr('data-class'));
            target.toggleClass(classes[index]);
            target.attr('data-class', _class);
            key++;
        });
        $self.toggleClass('active');
        $self.attr('href') === "#" ? e.preventDefault() : '';
    });

    const links = jQuery('a.scroll-target');
    links.on('click', function () {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') || location.hostname === this.hostname) {
            let target = jQuery(this.hash);
            target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                jQuery('html,body').animate({
                    scrollTop: target.offset().top - 75,
                }, 1000);
                return false;
            }
        }
    });

    jQuery('[data-toggle="tooltip"]').doOnce(function () {
        jQuery(this).tooltip();
    });

    jQuery('.share-link').click(function (e) {
        e.preventDefault();
        window.open(
            jQuery(this).attr('href'),
            'shareWindow',
            'height=450, width=550, top=' + (jQuery(window).height() / 2 - 275) + ', left=' + (jQuery(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
        );
        return false;
    });

    const requestSvg = async ($image) => {
        fetch($image.attr('data-src'), {
            mode: 'same-origin',
            headers: {
                'Content-Type': 'image/svg',
                'Connection': 'keep-alive',
            },
        }).then((data) => {
            return data.text();
        }).then((data) => {
            let $svg = jQuery(data);

            const imgClass = $image.attr('class');
            const imgID = $image.attr('id');

            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }

            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            $svg = $svg.removeAttr('xmlns:a');

            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            $image.replaceWith($svg);
        }).catch((error) => {
            console.log(error);
        });
    };

    jQuery('img.svg').each(function () {
        requestSvg(jQuery(this));
    });

    if (jQuery('select').length) {
        jQuery('.theme-select-menu').selectize();
    }

    const sticky = jQuery('.theme-main-menu');

    if (sticky.length) {
        jQuery(window).on('scroll', function () {
            const scroll = jQuery(window).scrollTop();
            if (scroll >= 100) {
                sticky.addClass('fixed');
            } else {
                sticky.removeClass('fixed');
            }
        });
    }

    jQuery("input,textarea").each(function () {
        jQuery(this).data('holder', jQuery(this).attr('placeholder'));
        jQuery(this).on('focusin', function () {
            jQuery(this).attr('placeholder', '');
        });
        jQuery(this).on('focusout', function () {
            jQuery(this).attr('placeholder', jQuery(this).data('holder'));
        });
    });

    const scrollTopArrow = jQuery('.scroll-top');

    if (scrollTopArrow.length) {
        jQuery(window).on('scroll', function () {
            if (jQuery(this).scrollTop() > 200) {
                scrollTopArrow.fadeIn();
            } else {
                scrollTopArrow.fadeOut();
            }
        });

        scrollTopArrow.on('click', function () {
            jQuery('html, body').animate({scrollTop: 0}, 1500);
            return false;
        });
    }

    jQuery('.timer').doOnce(function () {
        const _this = this;

        jQuery(_this).appear(function () {
            jQuery(_this).countTo();
        });
    });

    jQuery(".agn-testimonial-slider").doOnce(function () {
        jQuery(this).owlCarousel({
            loop: true,
            nav: true,
            navText: ["<i class='flaticon-back'></i>", "<i class='flaticon-next'></i>"],
            dots: false,
            autoplay: true,
            autoplayTimeout: 4000,
            smartSpeed: 1200,
            autoplayHoverPause: true,
            lazyLoad: true,
            items: 1
        });
    });

    jQuery(".blog-one-slider").doOnce(function () {
        this.owlCarousel({
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 4000,
            smartSpeed: 1200,
            autoplayHoverPause: true,
            lazyLoad: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3,
                }
            },
        });
    });

    jQuery(".partner-slider").doOnce(function () {
        jQuery(this).owlCarousel({
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplaySpeed: 1000,
            lazyLoad: true,
            singleItem: true,
            center: true,
            responsive: {
                0: {
                    items: 1
                },
                550: {
                    items: 3
                },
                992: {
                    items: 5
                }
            }
        });
    });

    jQuery(".customer-slider").doOnce(function () {
        jQuery(this).owlCarousel({
            loop: true,
            nav: true,
            navText: ["<i class='flaticon-back'></i>", "<i class='flaticon-next'></i>"],
            dots: false,
            autoplay: true,
            margin: 0,
            autoplayTimeout: 4500,
            autoplaySpeed: 1000,
            lazyLoad: true,
            singleItem: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                }
            }
        });
    });

    jQuery(".arch-client-slider").doOnce(function () {
        jQuery(this).owlCarousel({
            loop: true,
            nav: false,
            dots: true,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 4500,
            autoplaySpeed: 1000,
            lazyLoad: true,
            singleItem: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1100: {
                    items: 3
                }
            }
        });
    });

    jQuery(".related-product-slider").doOnce(function () {
        jQuery(this).owlCarousel({
            loop: true,
            nav: true,
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            dots: false,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 4500,
            autoplaySpeed: 1000,
            lazyLoad: true,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                },
                992: {
                    items: 3
                }
            }
        });
    });

    jQuery(".project-minimal-slider").doOnce(function () {
        jQuery(this).owlCarousel({
            loop: true,
            nav: true,
            navText: ["<i class='flaticon-back'></i>", "<i class='flaticon-next'></i>"],
            dots: false,
            autoplay: true,
            margin: 45,
            autoplayTimeout: 4500,
            autoplaySpeed: 1000,
            lazyLoad: true,
            singleItem: true,
            center: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1200: {
                    items: 3
                }
            }
        });
    });

    jQuery(".team-classic-slider").doOnce(function () {
        jQuery(this).owlCarousel({
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 4500,
            autoplaySpeed: 1000,
            lazyLoad: true,
            singleItem: true,
            responsive: {
                0: {
                    items: 1
                },
                550: {
                    items: 2
                },
                992: {
                    items: 3
                }
            }
        });
    });

    if (jQuery('.theme-accordion > .panel').length) {
        jQuery('.theme-accordion > .panel').on('show.bs.collapse', function () {
            const heading = jQuery(this).find('.panel-heading');
            heading.addClass("active-panel");
        }).on('hidden.bs.collapse', function () {
            const heading = jQuery(this).find('.panel-heading');
            heading.removeClass("active-panel");
        });

        jQuery('.panel-heading a').on('click', function (e) {
            if (jQuery(this).parents('.panel').children('.panel-collapse').hasClass('show')) {
                e.stopPropagation();
                e.preventDefault();
            }
        });
    }

    if (jQuery("#particles").length) {
        particlesJS("particles", {
            "particles": {
                "number": {
                    "value": 200,
                    "density": {
                        "enable": true,
                        "value_area": 800
                    }
                },
                "color": {
                    "value": "#636593"
                },
                "shape": {
                    "type": "circle",
                    "stroke": {
                        "width": 0,
                        "color": "#000000"
                    },
                    "polygon": {
                        "nb_sides": 5
                    },
                    "image": {
                        "src": "img/github.svg",
                        "width": 100,
                        "height": 100
                    }
                },
                "opacity": {
                    "value": 0.7,
                    "random": true,
                    "anim": {
                        "enable": true,
                        "speed": 1,
                        "opacity_min": 0,
                        "sync": false
                    }
                },
                "size": {
                    "value": 3,
                    "random": true,
                    "anim": {
                        "enable": false,
                        "speed": 4,
                        "size_min": 0.3,
                        "sync": false
                    }
                },
                "line_linked": {
                    "enable": false,
                    "distance": 150,
                    "color": "#ffffff",
                    "opacity": 0.4,
                    "width": 1
                },
                "move": {
                    "enable": true,
                    "speed": 1,
                    "direction": "none",
                    "random": true,
                    "straight": false,
                    "out_mode": "out",
                    "bounce": false,
                    "attract": {
                        "enable": false,
                        "rotateX": 600,
                        "rotateY": 600
                    }
                }
            },
            "interactivity": {
                "detect_on": "canvas",
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": "bubble"
                    },
                    "onclick": {
                        "enable": true,
                        "mode": "repulse"
                    },
                    "resize": true
                },
                "modes": {
                    "grab": {
                        "distance": 400,
                        "line_linked": {
                            "opacity": 1
                        }
                    },
                    "bubble": {
                        "distance": 250,
                        "size": 0,
                        "duration": 2,
                        "opacity": 0,
                        "speed": 3
                    },
                    "repulse": {
                        "distance": 400,
                        "duration": 0.4
                    },
                    "push": {
                        "particles_nb": 4
                    },
                    "remove": {
                        "particles_nb": 2
                    }
                }
            },
            "retina_detect": true
        });
    }

    jQuery(window).on('load', function () {
        jQuery('#ctn-preloader').fadeOut();
        jQuery('#preloader').delay(350).fadeOut('slow');
        jQuery('body').delay(350).css({'overflow': 'visible'});

        // if (jQuery("[data-aos]").length) {
        //     AOS.init({
        //         duration: 1000,
        //         mirror: true
        //     });
        // }

        if (jQuery(".wow").length) {
            const wow = new WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 20,
                mobile: true,
                live: false,
            });
            wow.init();
        }

        if (jQuery("#isotop-gallery-wrapper").length) {
            let $grid = new Isotope(jQuery('#isotop-gallery-wrapper').get(0), {
                itemSelector: '.isotop-item',
                percentPosition: true,
                masonry: {
                    columnWidth: '.grid-sizer'
                }
            });

            let filterValue = '*';

            jQuery('.isotop-menu-wrapper').on('click', 'li', function () {
                filterValue = jQuery(this).attr('data-filter');
                $grid.arrange({filter: filterValue});
            });

            jQuery('.isotop-menu-wrapper').each(function (i, buttonGroup) {
                const $buttonGroup = jQuery(buttonGroup);
                $buttonGroup.on('click', 'li', function () {
                    $buttonGroup.find('.is-checked').removeClass('is-checked');
                    jQuery(this).addClass('is-checked');
                });
            });

            // jQuery('#isotop-gallery-wrapper .isotop-item img:not(.svg)').on('load', function () {
            //     $grid.arrange({filter: filterValue});
            // });

            if(ajax_loadmore_options.max_page == 1) {
                $('#isotop-gallery-wrapper ~div .portfolio-load-more').remove();
            } else {
                $('#isotop-gallery-wrapper ~div .portfolio-load-more').css('display', 'inline-block');
            }

            $('#isotop-gallery-wrapper ~div .portfolio-load-more').click(function (e) {
                const button = $(this),
                    data = {
                        'action': 'rogan_get_portfolio_items',
                        'query': ajax_loadmore_options.posts,
                        'page': ++ajax_loadmore_options.current_page
                    };

                e.preventDefault();

                button.attr('disabled', 'disabled');

                $.ajax({
                    url: ajax_loadmore_options.ajaxurl,
                    data: data,
                    type: 'POST',
                    beforeSend: function (xhr) {
                        button.text('Ładowanie...');
                    },
                    success: function (response) {
                        const json = JSON.parse(response);

                        if (json.data) {
                            button.attr('disabled', false);
                            button.text('Pokaż więcej');

                            let newElements = $(json.data);

                            newElements = newElements.filter(function(i, item) {
                                return jQuery(item).hasClass('isotop-item');
                            });

                            $('#isotop-gallery-wrapper').append(newElements);

                            $grid.insert(newElements.get());

                            newElements.find('.svg').each(function () {
                                requestSvg($(this));
                            });

                            if (ajax_loadmore_options.current_page >= ajax_loadmore_options.max_page)
                                button.remove();
                        } else {
                            button.remove();
                        }
                    }
                });
            });
        }

        jQuery(".fancybox").doOnce(function () {
            jQuery(this).fancybox({
                arrows: true,
                buttons: [
                    "zoom",
                    "slideShow",
                    "thumbs",
                    "close"
                ],
                animationEffect: "zoom-in-out",
                transitionEffect: "zoom-in-out",
            });
        });
    });
})(jQuery);
